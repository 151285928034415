import React from "react"
import { graphql, StaticQuery } from "gatsby";
import { Location } from '@reach/router';

import Accordion from "global/Accordion";
import Icon from 'components/Icon';
import DataProvider from 'providers/data';
import zparse from "helpers/zparse";

const CommunityResourceHandler = (props) => {

	return (
		<StaticQuery
			query={graphql`
				query AllCommunityResources {
					allCommunityResource(filter: {approved: {eq: true}, archived: {eq: false}, deleted: {eq: false}}) {
						edges {
						  node {
							title
							content
						  }
						}
					}
				}
			`}
			render={(data) => {

				const { edges } = data.allCommunityResource;
				let resources = edges.map(({ node }) => node);
				if ((props.providerResponse.data.length > 0 || !!resources !== false) && props.providerStatusText === "LOADED") {
					resources = props.providerResponse.data;
				}

				if (resources.length === 0) return null;

				return (
					<section className="community-resources">
						<div className="grid-container">
							<div className="grid-x grid=padding-x">
								<div className="cell">
									<div className="community-resources smartlike-accordion">
										{resources.map((resource, index) => <Accordion key={`${resource.title}-${index}`} title={<h2>{resource.title} <Icon height="24px" width="30px" symbol="arrowDown" /></h2>} body={zparse(resource.content)} />)}
									</div>
								</div>
							</div>
						</div>
					</section>
				)


			}} />
	);
}

const CommunityResource = props => {
	return (
		<Location>
			{locationProps => <DataProvider liveRefresh={true} type="CommunityResource"><CommunityResourceHandler {...props} location={locationProps.location} /></DataProvider>}
		</Location>
	)
}

export default CommunityResource;