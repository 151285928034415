
import React from "react"
import { graphql } from 'gatsby';

import Layout from 'templates/Layout';
import Content from 'templates/Content';
import Callout from 'templates/community-resource-center/Callout';
import Resource from 'templates/community-resource-center/Resource';

import DataProvider from 'providers/data';



const CommunityResourceCenter = (props) => {
	const { edges } = props.data.allContentPage;
	const nodes = edges.map(({ node }) => node);

	let page = nodes[0];
	if ((props.providerResponse.data.length > 0 || !!page !== false) && props.providerStatusText === 'LOADED') {
		page = props.providerResponse.data[0];
	}

	return (
		<Layout>
			<Content {...page} />
			<Callout />
			<Resource />
		</Layout>
	)
}

const getData = (props) => <DataProvider liveRefresh={props.params.liveRefresh} type="ContentPage" apiParams={{ uri: "/community-resource-center/", _join: "Staff" }}><CommunityResourceCenter {...props} /></DataProvider>
export default getData;

export { Head } from 'components/templates/Head';

export const query = graphql`
{
	allContentPage(
	  filter: {uri: {eq: "/community-resource-center/"}, approved: {eq: true}, archived: {eq: false}, deleted: {eq: false}}
	) {
	  edges {
		node {
		  pageTitle
		  meta
		  content {
			main
		  }
		  headlineBody
		  headlineTitle
		  headlineImage
		  headlineType
		  headlineBgColor
		  headlineColor
		}
	  }
	}
}
`
