import React from "react"
import { graphql, StaticQuery } from "gatsby";
import { Location } from '@reach/router';

import Link from 'components/Link'
import DataProvider from 'providers/data';

const CommunityCalloutHandler = (props) => {

	return (
		<StaticQuery
			query={graphql`
				query AllCommunityCallouts {
					allCommunityCallout(
						filter: {approved: {eq: true}, archived: {eq: false}, deleted: {eq: false}}
						sort: {order: ASC, fields: sort}
					) {
						edges {
						  node {
							image
							title
							link
						  }
						}
					}
				}
			`}
			render={(data) => {

				const { edges } = data.allCommunityCallout;
				let callouts = edges.map(({ node }) => node);
				if ((props.providerResponse.data.length > 0 || !!callouts !== false) && props.providerStatusText === "LOADED") {
					callouts = props.providerResponse.data;
				}

				if (callouts.length === 0) return null;

				return (
					<section className="community-callouts">
						<div className="grid-container">
							<div className="grid-x grid-padding-x small-up-1 medium-up-2 large-up-3">
								{callouts.map((callout, index) => (
									<div key={`${callout.title}-${index}`} className="callout cell">
										<Link to={callout.link}>
											<div className="callout-inner-wrapper" style={{ backgroundImage: `url(${callout.image})` }}>
												<h2>{callout.title}</h2>
											</div>
										</Link>
									</div>
								))}
							</div>
						</div>
					</section>
				)


			}} />
	);
}

const CommunityCallout = props => {
	return (
		<Location>
			{locationProps => <DataProvider liveRefresh={true} type="CommunityCallout" apiParams={{ approved: true, archived: false, deleted: false, _sort: "sort", _order: "asc" }}><CommunityCalloutHandler {...props} location={locationProps.location} /></DataProvider>}
		</Location>
	)
}

export default CommunityCallout;